export const GET_EXERCISES = 'GET_EXERCISES';
export const ADD_EXERCISE = 'ADD_EXERCISET';
export const UPDATE_EXERCISE = 'UPDATE_EXERCISE';
export const DELETE_EXERCISE = 'DELETE_EXERCISE';
export const EXERCISE_ERROR = 'EXERCISE_ERROR';

export const SET_CURRENT = 'SET_CURRENT';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';

export const FILTER_EXERCISES = 'FILTER_EXERCISES';
export const CLEAR_EXERCISES = 'CLEAR_EXERCISES';
export const CLEAR_FILTER = 'CLEAR_FILTER';



export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_UPLOADED';
export const AUTH_ERROR = 'AUTH_ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL= 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';